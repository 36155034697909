import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createProject } from "../../api/projects";
import { useAllPartnersHooks } from "../../utils/project/PartnerUtils";
import useRegionHooks from "../../utils/RegionHooks";
import AlertBox from "../AlertBox";
import { RequiredAsterisk } from "../FormHelper";
import FramedPoolForm from "./FramedPoolForm";
import OltForm from "./OltForm";
import Toggle from "react-toggle";

export default function ModalProjectCreate({ show, onClose, onSuccess }) {
  let [loading, setLoading] = useState(false);
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({
    name: "",
    pic_name: "",
    slug: "",
    partner_id: "",
    framed_pool: null,
    new_framed_pool: "",
    olt: null,
    vlan: "",
    vlan_profile: "",
    user_activation: 0,
    commission_disbursement: 0,
    commissions: [
      { type: "technician", installation: 0, relocate: 0, dismantle: 0 },
      { type: "technician-freelance", installation: 0, relocate: 0, dismantle: 0 },
      { type: "helper", installation: 0, relocate: 0, dismantle: 0 },
      { type: "helper-freelance", installation: 0, relocate: 0, dismantle: 0 },
      { type: "sales", installation: 0 },
      { type: "sales-freelance", installation: 0 },
    ],
  });
  let address = useRegionHooks();
  let { partners } = useAllPartnersHooks();

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setAlert({ show: false, message: "", type: "" });
    setForm({
      name: "",
      pic_name: "",
      slug: "",
      partner_id: "",
      framed_pool: null,
      new_framed_pool: "",
      olt: null,
      vlan: "",
      vlan_profile: "",
      user_activation: 0,
      commission_disbursement: 0,
      commissions: [
        { type: "technician", installation: 0, relocate: 0, dismantle: 0 },
        { type: "technician-freelance", installation: 0, relocate: 0, dismantle: 0 },
        { type: "helper", installation: 0, relocate: 0, dismantle: 0 },
        { type: "helper-freelance", installation: 0, relocate: 0, dismantle: 0 },
        { type: "sales", installation: 0 },
        { type: "sales-freelance", installation: 0 },
      ],
    });
    address.resetRegion();
  };

  const sendDataProject = async (formData) => {
    setLoading(true);
    try {
      let response = await createProject(formData);
      onSuccess(true, response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }
    setLoading(false);
  };

  const onChangeCommission = (index, key, value) => {
    let tempForm = { ...form };
    tempForm.commissions[index][key] = value;
    setForm({ ...tempForm });
  };

  const onSubmitData = (event) => {
    event.preventDefault();
    let formData = {
      ...form,
      address: address.address,
      rt: address.rt,
      rw: address.rw,
      address_id: address.addressId,
    };

    if (formData.framed_pool?.value) {
      if (formData.framed_pool?.value === "add-new") {
        formData.framed_pool = "";
      } else {
        formData.framed_pool = formData.framed_pool.value;
        formData.new_framed_pool = "";
      }
    } else {
      formData.framed_pool = "";
    }

    formData.olt_id = form.olt?.value || "";
    delete formData.olt;
    // console.log(formData);
    sendDataProject(formData);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <form onSubmit={(event) => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Data Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="row">
            <div className="col-12 col-lg-6">
              <h4>Data Project</h4>
              <div className="form-group">
                <label htmlFor="projectname">
                  Nama Project <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  required
                  value={form.name}
                  onChange={(event) => setForm({ ...form, name: event.target.value })}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="slugname">Slug</label>
                <input
                  type="text"
                  value={form.slug}
                  onChange={(event) => setForm({ ...form, slug: event.target.value })}
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label htmlFor="projectname">
                  Nama PIC <i>(Person In Charge)</i> <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  required
                  value={form.pic_name}
                  onChange={(event) => setForm({ ...form, pic_name: event.target.value })}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="partnername">Mitra/Partner</label>
                <select
                  name="partnername"
                  id="partnername"
                  className="form-control"
                  value={form.partner_id}
                  onChange={(event) => setForm({ ...form, partner_id: event.target.value })}
                >
                  <option value="">-- Pilih Partner --</option>
                  {partners.map((partner, index) => (
                    <option key={index} value={partner.id}>
                      {partner.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Alamat <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  required
                  className="form-control"
                  value={address.address}
                  onChange={(event) => address.setAddress(event.target.value)}
                />
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="projectname">
                      RT <RequiredAsterisk />
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={address.rt}
                      onChange={(event) => address.setRt(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-auto">/</div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="projectname">
                      RW <RequiredAsterisk />
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={address.rw}
                      onChange={(event) => address.setRw(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Provinsi <RequiredAsterisk />
                </label>
                <select
                  name="provinsi"
                  requiredid="provinsi"
                  className="form-control"
                  value={address.province}
                  onChange={(event) => address.changeProvince(event.target.value)}
                >
                  <option value="">-- Pilih Provinsi --</option>
                  {address.provinces.map((province, index) => (
                    <option key={index} value={province.province}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Kota/Kabupaten <RequiredAsterisk />
                </label>
                <select
                  name="kabupaten"
                  requiredid="kabupaten"
                  className="form-control"
                  value={address.city}
                  onChange={(event) => address.changeCity(event.target.value)}
                >
                  <option value="">-- Pilih Kabupaten/Kota --</option>
                  {address.cities.map((city, index) => (
                    <option key={index} value={city.kota_kab}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Kecamatan <RequiredAsterisk />
                </label>
                <select
                  name="kecamatan"
                  required
                  id="kecamatan"
                  className="form-control"
                  value={address.district}
                  onChange={(event) => address.changeDistrict(event.target.value)}
                >
                  <option value="">-- Pilih Kecamatan --</option>
                  {address.districts.map((district, index) => (
                    <option key={index} value={district.kecamatan}>
                      {district.kecamatan}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="projectname">
                  Kelurahan <RequiredAsterisk />
                </label>
                <select
                  name="kelurahan"
                  required
                  id="kelurahan"
                  className="form-control"
                  value={address.addressId}
                  onChange={(event) => address.setAddressId(event.target.value)}
                >
                  <option value="">-- Pilih Kelurahan --</option>
                  {address.addresslist.map((kelurahan, index) => (
                    <option key={index} value={kelurahan.id}>
                      {kelurahan.kelurahan}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="card mb-4">
                <div className="card-body">
                  <h4>Setting Aktivasi</h4>
                  <div className="form-group">
                    <label label="framedPool">
                      Framed Pool <RequiredAsterisk />
                    </label>
                    <FramedPoolForm
                      value={form.framed_pool}
                      onChange={(value) => setForm({ ...form, framed_pool: value })}
                      new_value={form.new_framed_pool}
                      onChangeNew={(value) => setForm({ ...form, new_framed_pool: value })}
                    />
                  </div>
                  <div className="form-group">
                    <label label="OLTid">
                      OLT <RequiredAsterisk />
                    </label>
                    <OltForm
                      value={form.olt}
                      onChange={(value) => setForm({ ...form, olt: value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="vlanname">
                      VLAN <RequiredAsterisk />
                    </label>
                    <input
                      id="vlanname"
                      type="text"
                      value={form.vlan}
                      onChange={(event) => setForm({ ...form, vlan: event.target.value })}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="vlanprofile">
                      VLAN Profile <RequiredAsterisk />
                    </label>
                    <input
                      id="vlanprofile"
                      type="text"
                      value={form.vlan_profile}
                      onChange={(event) => setForm({ ...form, vlan_profile: event.target.value })}
                      className="form-control"
                    />
                  </div>
                  <div className="row form-group">
                    <div className="col-auto">Aktivasi Internet oleh Pengguna</div>
                    <div className="col-auto">
                      <Toggle
                        checked={form.user_activation === 1}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setForm({ ...form, user_activation: 1 });
                          } else {
                            setForm({ ...form, user_activation: 0 });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4>Setting Komisi</h4>
                  <div className="row form-group">
                    <div className="col-auto">Pencairan komisi dengan aplikasi Pulsa Pro</div>
                    <div className="col-auto">
                      <Toggle
                        checked={form.commission_disbursement === 1}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setForm({ ...form, commission_disbursement: 1 });
                          } else {
                            setForm({ ...form, commission_disbursement: 0 });
                          }
                        }}
                      />
                    </div>
                  </div>
                  {form.commissions.map((commission, index) => (
                    <div key={index}>
                      <h5>Komisi {CommmissionTypeRole(commission.type)}</h5>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label>Instalasi</label>
                            <input
                              type="number"
                              className="form-control"
                              value={commission.installation}
                              onChange={(event) =>
                                onChangeCommission(index, "installation", event.target.value)
                              }
                              disabled={form.commission_disbursement !== 1}
                              required={form.commission_disbursement === 1}
                            />
                          </div>
                        </div>
                        {commission.relocate !== undefined && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label>Relokasi</label>
                              <input
                                type="number"
                                className="form-control"
                                value={commission.relocate}
                                onChange={(event) =>
                                  onChangeCommission(index, "relocate", event.target.value)
                                }
                                disabled={form.commission_disbursement !== 1}
                                required={form.commission_disbursement === 1}
                              />
                            </div>
                          </div>
                        )}
                        {commission.dismantle !== undefined && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label>Dismantle</label>
                              <input
                                type="number"
                                className="form-control"
                                value={commission.dismantle}
                                onChange={(event) =>
                                  onChangeCommission(index, "dismantle", event.target.value)
                                }
                                disabled={form.commission_disbursement !== 1}
                                required={form.commission_disbursement === 1}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">
            Close
          </button>
          <button className="btn btn-primary" disabled={loading}>
            {loading ? "Membuat project ..." : "Buat Project Baru"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

function CommmissionTypeRole(val) {
  const ObjTypeRole = {
    technician: "Teknisi",
    "technician-freelance": "Teknisi Freelance",
    helper: "Helper",
    "helper-freelance": "Helper Freelance",
    sales: "Sales",
    "sales-freelance": "Sales Freelance",
  };

  return ObjTypeRole[val] || "-";
}
