import { useEffect, useState } from "react";
import {
  useInventoriesByWarehouseHooks,
  useModemBySerialHooks,
  useWarehousesHooks,
} from "../../../utils/InventoryHooks";
import AlertBox from "../../AlertBox";
import Box from "../../Box";
import { CONDITION_INVENTORY } from "../../../tools/inventorytools";

let filterWarehouse = { page: 1, page_size: 100 };

export default function BoxFormInventory({ addInventory }) {
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [typeInv, setTypeInv] = useState("");

  const onSubmitInvData = (value = {}) => {
    let temp_inv = { ...value.selectedInventory, warehouse: value.warehouse };
    addInventory(temp_inv, value.qty, null, value.status);
  };

  const onSubmitModemData = (value = {}) => {
    let temp_inv = {
      ...value.selectedInventory,
      warehouse: value.warehouse,
      serial: value.serial,
      qty: value.qty,
      note: value.note,
    };
    addInventory(temp_inv, value.qty, value.modem_id, value.status);
  };

  return (
    <Box title="Form Barang Kembali">
      <AlertBox {...alert} setAlert={setAlert} />
      <div className="form-group">
        <label>Pilih Tipe Inventory</label>
        <select
          className="form-control"
          value={typeInv}
          onChange={(event) => setTypeInv(event.target.value)}
        >
          <option value="">-- Pilih Tipe --</option>
          <option value="inventory">Inventory</option>
          <option value="modem">Modem</option>
        </select>
      </div>

      {typeInv === "modem" && <FormInputModem onSubmit={onSubmitModemData} />}
      {typeInv === "inventory" && <FormInputInventory onSubmit={onSubmitInvData} />}
    </Box>
  );
}

function FormInputInventory({ onSubmit }) {
  let [form, setForm] = useState({
    warehouse_id: "",
    inventory_id: "",
    status: "",
    qty: 1,
    selectedInventory: {},
  });

  let [filterInv, setFilterInv] = useState({ page: 1, page_size: 100, id: "", modem: false });

  let { warehouses, loading: warehouseLoading } = useWarehousesHooks({ filter: filterWarehouse });
  let { inventories } = useInventoriesByWarehouseHooks({ filter: filterInv });

  const onChangeWarehouse = (value) => {
    setForm({ ...form, warehouse_id: value, inventory_id: "", selectedInventory: {} });
    setFilterInv({ ...filterInv, id: value });
  };

  const onChangeInventory = (value) => {
    let index = inventories.findIndex((inventory) => inventory.id === value);
    let selectedInventory = { ...inventories[index] };
    setForm({ ...form, inventory_id: value, selectedInventory: selectedInventory });
  };

  const onSubmitInventory = (event) => {
    event.preventDefault();

    let warehouse = warehouses.find((warehouse) => warehouse.id === form.warehouse_id);
    let temp_inv = {
      selectedInventory: form.selectedInventory,
      warehouse: warehouse,
      status: form.status,
      qty: form.qty,
    };

    onSubmit(temp_inv);
    setForm({
      warehouse_id: "",
      inventory_id: "",
      status: "",
      qty: 1,
      selectedInventory: {},
    });
  };

  return (
    <form onSubmit={(event) => onSubmitInventory(event)}>
      <div className="form-group">
        <label>Pilih Gudang</label>
        <select
          className="form-control"
          value={form.warehouse_id}
          onChange={(event) => onChangeWarehouse(event.target.value)}
          disabled={warehouseLoading}
          required
        >
          <option value=""> -- Pilih Warehouse -- </option>
          {warehouses.map((warehouse, index) => (
            <option value={warehouse.id} key={index}>
              {warehouse.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Pilih Barang</label>
        <select
          className="form-control"
          value={form.inventory_id}
          onChange={(event) => onChangeInventory(event.target.value)}
          required
        >
          <option value=""> -- Pilih Barang/Inventory -- </option>
          {inventories.map((inventory, index) => (
            <option value={inventory.id} key={index}>
              {inventory.name} (Stok: {inventory.stock} {inventory.unit})
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Kuantitas</label>
        <input
          type="number"
          min={1}
          className="form-control"
          value={form.qty}
          onChange={(event) => setForm({ ...form, qty: event.target.value })}
          required
        />
      </div>
      <div className="form-group">
        <label>Status Kondisi</label>
        <select
          className="form-control"
          value={form.status}
          onChange={(event) => setForm({ ...form, status: event.target.value })}
          required
        >
          <option value="">-- Kondisi Barang -- </option>
          <option value={0}>Rusak</option>
          <option value={1}>Normal</option>
        </select>
      </div>

      <button type="submit" className="btn btn-primary">
        <i className="fas fa-plus"></i> Tambah ke Daftar Konfirmasi Inventory
      </button>
    </form>
  );
}

function FormInputModem({ onSubmit }) {
  let { data: modem, loading, fetched, error, getModem, reset } = useModemBySerialHooks();
  let [serial, setSerial] = useState("");
  let { warehouses } = useWarehousesHooks({ filter: filterWarehouse });

  let [form, setForm] = useState({
    warehouse_id: "",
    inventory_id: "",
    status: "",
    qty: 1,
    selectedInventory: {},
    note: "",
  });

  useEffect(() => {
    if (fetched) {
      setForm({
        warehouse_id: modem.warehouse_id,
        inventory_id: modem.inventory.id,
        status: "",
        qty: 1,
        selectedInventory: { ...modem.inventory },
        note: "",
      });
    }
  }, [fetched, modem]);

  const checkSerial = () => {
    if (serial) {
      getModem(serial, true);
    }
  };

  const resetForm = () => {
    setSerial("");
    reset();
    setForm({
      warehouse_id: "",
      inventory_id: "",
      status: "",
      qty: 1,
      selectedInventory: {},
      note: "",
    });
  };

  const onSubmitInventory = (event) => {
    event.preventDefault();
    let warehouse = warehouses.find((warehouse) => warehouse.id === form.warehouse_id);

    let temp_inv = {
      selectedInventory: form.selectedInventory,
      modem_id: modem.id,
      serial: serial,
      warehouse: warehouse,
      status: form.status,
      qty: form.qty,
      note: form.note,
    };

    onSubmit(temp_inv);
    resetForm();
  };

  return (
    <form onSubmit={(event) => onSubmitInventory(event)}>
      <div className="form-group">
        <label>Nomor Serial Modem</label>
        <div className="row">
          <div className="col">
            <input
              type="text"
              placeholder="Nomor Serial Modem (Jika modem)"
              className="form-control"
              value={serial}
              onChange={(event) => setSerial(event.target.value)}
            />
          </div>
          <div className="col-auto">
            <button type="button" className="btn btn-primary" onClick={() => checkSerial()}>
              Cek Modem
            </button>
          </div>
        </div>
        {loading && <span className="text-dark">Mengecek modem...</span>}
        {fetched && <span className="text-success">Modem valid</span>}
        {error && <span className="text-danger">Modem tidak valid ({modem.error})</span>}
      </div>
      <div className="form-group">
        <label>Pilih Gudang</label>
        <select
          className="form-control"
          value={form.warehouse_id}
          onChange={(event) => setForm({ ...form, warehouse_id: event.target.value })}
          required
        >
          <option value=""> -- Pilih Warehouse -- </option>
          {warehouses.map((warehouse, index) => (
            <option value={warehouse.id} key={index}>
              {warehouse.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Inventory</label>
        <input
          className="form-control"
          type="text"
          disabled
          value={fetched ? `(${form.selectedInventory?.sku}) ${form.selectedInventory?.name}` : ""}
        />
      </div>
      <div className="form-group">
        <label>Status Kondisi</label>
        <select
          className="form-control"
          value={form.status}
          onChange={(event) => setForm({ ...form, status: event.target.value })}
          required
        >
          <option value="">-- Kondisi Barang -- </option>
          {CONDITION_INVENTORY.map((condition, index) => (
            <option value={index}>{condition}</option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Catatan</label>
        <input
          type="text"
          className="form-control"
          value={form.note}
          onChange={(event) => setForm({ ...form, note: event.target.value })}
        />
      </div>
      <button type="submit" className="btn btn-primary" disabled={!fetched}>
        <i className="fas fa-plus"></i> Tambah ke Daftar Konfirmasi Inventory
      </button>
    </form>
  );
}
