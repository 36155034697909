import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateModemById } from "../../../api/inventory";
import { useModemHooks } from "../../../utils/InventoryHooks";
import { RequiredAsterisk } from "../../FormHelper";
import AlertBox from "../../AlertBox";
import { CONDITION_INVENTORY } from "../../../tools/inventorytools";

export default function ModalUpdateModem({ show, onClose, id, inventoryId, onSuccess }) {
  const { data, fetched } = useModemHooks({ id, inventoryId });
  let [form, setForm] = useState({
    brand: "",
    type: "",
    serial_number_modem: "",
    serial_number_gpon: "",
    status: 1,
    note: "",
  });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    if (fetched) {
      setForm({
        brand: data.brand,
        type: data.type,
        serial_number_modem: data.serial_number_modem,
        serial_number_gpon: data.serial_number_gpon,
        status: data.status,
        note: data.note || "",
      });
    }
  }, [fetched]);

  const handleClose = () => {
    setForm({
      brand: "",
      type: "",
      serial_number_modem: "",
      serial_number_gpon: "",
      status: 1,
      note: "",
    });
    setAlert({ show: false, message: "", type: "" });
    onClose(false);
  };

  const submitData = (event) => {
    event.preventDefault();
    submitCategory(form);
  };

  const submitCategory = async (form) => {
    setLoading(true);
    try {
      let response = await updateModemById(inventoryId, id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Data Modem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label htmlFor="">
              Brand Modem <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.brand}
              onChange={(event) => setForm({ ...form, brand: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Tipe Modem <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.type}
              onChange={(event) => setForm({ ...form, type: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Serial Number Modem <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.serial_number_modem}
              onChange={(event) => setForm({ ...form, serial_number_modem: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Serial Number GPON <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.serial_number_gpon}
              onChange={(event) => setForm({ ...form, serial_number_gpon: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Status Kondisi</label>
            <select
              className="form-control"
              value={form.status}
              onChange={(event) => setForm({ ...form, status: event.target.value })}
            >
              <option>-- Kondisi Barang -- </option>
              {CONDITION_INVENTORY.map((condition, index) => (
                <option value={index}>{condition}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Catatan</label>
            <input
              type="text"
              className="form-control"
              value={form.note}
              onChange={(event) => setForm({ ...form, note: event.target.value })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-save"></i> {loading ? "Menyimpan ..." : "Simpan Perubahan"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
