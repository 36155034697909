import { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { useCityRegionHooks } from "../../utils/RegionHooks";
import * as api from "../../api/account";
import ImageUploader from "../ImageUploader";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import { useRolesListHooks } from "../../utils/PermissionManageUtils";
import { RequiredAsterisk } from "../FormHelper";
import ConfidentialImageUploader from "../ConfidentialImageUploader";
import Toggle from "react-toggle";

function canUpdateIdData(roles = []) {
  const ALLOW_ID_DATA = ["sales", "lead-sales"];

  for (let i = 0; i < roles.length; i++) {
    if (ALLOW_ID_DATA.includes(roles[i].label)) {
      return true;
    }
  }
  return false;
}

export default function ModalCreate(props) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    password: "",
    email: "",
    phone_number: "",
    image_url: "",
    employment_type: "full-time",
    commission_disbursement: 0,
    disbursement_account_id: "",
    id_card_number: "",
    id_card_name: "",
    id_card_image_url: "",
    disbursement_service: "pulsapro",
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [projectOpt, setProjectOpt] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const [roleOpt, setRoleOpt] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);

  const projects = useGroupListHooks();
  const region = useCityRegionHooks();
  const { roles } = useRolesListHooks();

  useEffect(() => {
    let rolesTemp = [];

    roles.forEach((project) => {
      let temp = {
        value: project.id,
        label: project.name,
      };
      rolesTemp.push(temp);

      return true;
    });

    setRoleOpt(rolesTemp);
  }, [roles]);

  useEffect(() => {
    let projectsTemp = [];

    projects.projects.forEach((project) => {
      let temp = {
        value: project.id,
        label: project.name,
      };
      projectsTemp.push(temp);

      return true;
    });

    setProjectOpt(projectsTemp);
  }, [projects.projects]);

  const submitData = (event) => {
    event.preventDefault();

    let formData = {
      ...form,
      address_id: String(region.city),
      roles: [],
    };

    if (selectedProjects.length > 0) {
      formData.projects = [];
      selectedProjects.forEach((project) => {
        formData.projects.push({
          id: project.value,
        });
      });
    }

    if (selectedRole.length > 0) {
      formData.roles = [];
      selectedRole.forEach((role) => {
        formData.roles.push({
          id: role.value,
        });
      });
    }

    sendData(formData);
  };

  const sendData = async (form) => {
    setLoading(true);
    try {
      let { data } = await api.createAccount({ ...form });
      console.log(data);
      handleClose();
      props.onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url });
  };

  const handleChangeRole = (selected) => {
    setSelectedRole(selected);
  };

  const handleClose = () => {
    // reseting all state
    setForm({
      name: "",
      password: "",
      email: "",
      phone_number: "",
      image_url: "",
      employment_type: "full-time",

      commission_disbursement: 0,
      disbursement_account_id: "",
      id_card_number: "",
      id_card_name: "",
      id_card_image_url: "",
      disbursement_service: "pulsapro",
    });
    setSelectedProjects([]);
    setSelectedRole([]);
    setAlert({ show: false, message: "", type: "" });
    region.resetRegion();

    props.setShow("");
  };

  const handleChangeProject = (selected) => {
    setSelectedProjects([...selected]);
  };

  return (
    <Modal show={props.show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Buat Akun Baru</Modal.Title>
      </Modal.Header>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Body>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="nameCreate">
                  {" "}
                  Nama <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form.name}
                  onChange={(event) => setForm({ ...form, name: event.target.value })}
                  id="nameCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="emailCreate">
                  {" "}
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  value={form.email}
                  onChange={(event) => setForm({ ...form, email: event.target.value })}
                  id="emailCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="passwordCreate">
                  {" "}
                  Password <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  value={form.password}
                  onChange={(event) => setForm({ ...form, password: event.target.value })}
                  id="passwordCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumbCreate">
                  Nomor Telp <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form.phone_number}
                  onChange={(event) => setForm({ ...form, phone_number: event.target.value })}
                  id="phoneNumbCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label>Foto Akun</label>
                <ImageUploader
                  image={form.image_url}
                  type="image_url"
                  onChangeImg={onChangeImg}
                  required={false}
                />
              </div>
              <div className="form-group">
                <label htmlFor="provinsi">
                  Provinsi <span className="text-danger">*</span>
                </label>
                <select
                  name="provinsi"
                  id="provinsi"
                  className="form-control"
                  value={region.province}
                  onChange={(event) => region.setProvince(event.target.value)}
                  required
                >
                  <option value="">-- Pilih Provinsi --</option>
                  {region.provinces.map((province, index) => (
                    <option key={index} value={province.province}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="kabupaten">
                  Area Kabupaten/Kota <span className="text-danger">*</span>
                </label>
                <select
                  name="kabupaten"
                  id="kabupaten"
                  className="form-control"
                  value={region.city}
                  onChange={(event) => region.setCity(event.target.value)}
                  required
                >
                  <option value="">-- Pilih Kabupaten/Kota --</option>
                  {region.cities.map((city, index) => (
                    <option key={index} value={city.id}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="project">Kelompok/Project</label>
                <Select
                  isMulti
                  placeholder="Kelompok / Project"
                  onChange={(selected) => handleChangeProject(selected)}
                  options={projectOpt}
                />
              </div>
              <div className="form-group">
                <label htmlFor="project">
                  Multi Role <span className="text-danger">*</span>
                </label>
                <Select
                  value={selectedRole}
                  isMulti
                  isClearable
                  placeholder="Role"
                  required
                  onChange={(selected) => handleChangeRole(selected)}
                  options={roleOpt}
                />
              </div>
              <div className="form-group">
                <label>
                  Tipe Pekerjaan <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  required
                  value={form.employment_type}
                  onChange={(event) => setForm({ ...form, employment_type: event.target.value })}
                >
                  <option value="">-- Pilih Tipe Pekerjaan --</option>
                  <option value="full-time">Full Time</option>
                  <option value="freelancer">Freelance</option>
                </select>
              </div>
              {canUpdateIdData(selectedRole) ? (
                <>
                  <div className="form-group">
                    <label htmlFor="">
                      Nomor KTP <RequiredAsterisk />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={form.id_card_number}
                      onChange={(event) => setForm({ ...form, id_card_number: event.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Nama KTP <RequiredAsterisk />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={form.id_card_name}
                      onChange={(event) => setForm({ ...form, id_card_name: event.target.value })}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>
                      Foto KTP <RequiredAsterisk />
                    </label>
                    <ConfidentialImageUploader
                      image={form.id_card_image_url}
                      type="id_card_image_url"
                      onChangeImg={(type, image_url) => {
                        setForm({ ...form, [type]: image_url });
                      }}
                      required={true}
                    />
                  </div>
                  <div className="row form-group">
                    <div className="col-auto">Pencairan komisi dengan aplikasi Pulsa Pro</div>
                    <div className="col-auto">
                      <Toggle
                        checked={form.commission_disbursement === 1}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setForm({ ...form, commission_disbursement: 1 });
                          } else {
                            setForm({ ...form, commission_disbursement: 0 });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Akun Pulsa Pro</label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.disbursement_account_id}
                      disabled={form.commission_disbursement === 0}
                      onChange={(event) =>
                        setForm({ ...form, disbursement_account_id: event.target.value })
                      }
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">
            Close
          </button>
          <button className="btn btn-primary" disabled={loading}>
            {loading ? "Membuat akun ..." : "Buat Akun"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
