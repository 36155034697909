import { useState } from "react";
import { returnInventories } from "../../api/inventory";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import BoxFormInventory from "../../components/inventory/in/BoxFormInventory";
import MainLayout from "../../components/layouts/MainLayout";
import { CONDITION_INVENTORY } from "../../tools/inventorytools";

export default function ReturnInventoryPage() {
  let [inventories, setInventories] = useState([]);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);

  const addInventory = (inventory, qty, modem_id = null, status) => {
    let temp_inv = [...inventories];
    temp_inv.push({ ...inventory, qty, modem_id, status });

    setInventories(temp_inv);
  };

  const removeInventory = (index) => {
    let temp_inv = [...inventories];
    temp_inv.splice(index, 1);
    setInventories(temp_inv);
  };

  const onSuccessSelect = (message) => {
    setAlert({ show: true, message, type: "success" });
  };

  const submitData = () => {
    if (inventories.length === 0) {
      setAlert({ show: true, message: "Lengkapi data inventory terlebih dahulu", type: "danger" });
      return;
    }

    let formData = {
      inventories: [],
    };

    for (let index = 0; index < inventories.length; index++) {
      let inventory = inventories[index];

      if (inventory.inventory_category.name.toUpperCase() === "MODEM") {
        formData.inventories.push({
          id: inventory.id,
          qty: inventory.qty,
          modem_id: inventory.modem_id,
          status: inventory.status,
          warehouse_id: inventory.warehouse.id,
          note: inventory.note,
        });
      } else {
        formData.inventories.push({
          id: inventory.id,
          qty: inventory.qty,
          status: inventory.status,
          warehouse_id: inventory.warehouse.id,
        });
      }
    }

    acceptRequest(formData);
  };

  const acceptRequest = async (form) => {
    setLoading(true);
    try {
      let response = await returnInventories(form);
      setInventories([]);
      setAlert({ show: true, message: response.data.message, type: "success" });
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Barang Kembali</h1>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <BoxFormInventory addInventory={addInventory} onSuccess={onSuccessSelect} />
          </div>
          <div className="col-lg-8">
            <Box title="Konfirmasi Barang Kembali">
              <AlertBox {...alert} setAlert={setAlert} />
              <div className="mb-3" style={{ overflowX: "auto" }}>
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>Nomor Serial</th>
                      <th>Nama Barang</th>
                      <th>Kategori</th>
                      <th>Qty</th>
                      <th>Gudang</th>
                      <th>Kondisi</th>
                      <th>Catatan</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventories.length === 0 && (
                      <tr>
                        <td colSpan={9} className="text-center">
                          Belum ada data yang dipilih
                        </td>
                      </tr>
                    )}
                    {inventories.map((inventory, index) => (
                      <tr key={index}>
                        <td>{inventory.sku}</td>
                        <td>{inventory.serial || "-"}</td>
                        <td>{inventory.name}</td>
                        <td>{inventory.inventory_category.name}</td>
                        <td>
                          {inventory.qty} {inventory.unit}
                        </td>
                        <td>{inventory.warehouse.name}</td>
                        <td>{CONDITION_INVENTORY[inventory.status]}</td>
                        <td>{inventory.note || "-"}</td>
                        <td>
                          <button className="btn btn-danger" onClick={() => removeInventory(index)}>
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <button
                type="button"
                disabled={loading}
                className="btn btn-success"
                onClick={() => submitData()}
              >
                <i className="fa fa-check-double"></i>{" "}
                {loading ? "Menyimpan ..." : "Konfirmasi Barang Kembali"}
              </button>
            </Box>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
