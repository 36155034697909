import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import AlertBox from "../../components/AlertBox";
import moment from "moment";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import Pagination from "../../components/Pagination";
import { useReportReminderDueCustomer } from "../../utils/customer/CustomerReminderUtils";
import { Modal } from "react-bootstrap";
import { downloadReportCustomerDueReminder } from "../../api/customer";

export default function ReportReminderCustomer() {
  const { hasAccess } = useControlPermission();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [modal, setModal] = useState(null);

  const [formFilter, setFormFilter] = useState({
    q: "",
    reminder_start_date: "",
    reminder_end_date: "",
    reminder_days_after_due: "",
  });

  const [filter, setFilter] = useState({
    q: "",
    reminder_start_date: "",
    reminder_end_date: "",
    reminder_days_after_due: "",
    page: 1,
    page_size: 20,
  });

  let { data: reports, meta, loading, errorMsg } = useReportReminderDueCustomer({ filter });

  const applyFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
      page: 1,
    });
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const onSuccess = (message) => {
    applyFilter();
    setAlert({ show: true, message, type: "success" });
  };

  // const onError = (message) => {
  //   setAlert({ show: true, message, type: "danger" });
  // };

  const resetFilter = () => {
    setFormFilter({
      q: "",
      reminder_start_date: "",
      reminder_end_date: "",
      reminder_days_after_due: "",
    });

    setFilter({
      q: "",
      reminder_start_date: "",
      reminder_end_date: "",
      reminder_days_after_due: "",
      page: 1,
      page_size: 20,
    });
  };

  return (
    <MainLayout resource_key="manage-customer">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1 className="h3 mb-4 text-gray-800">Report Pengingat Pelanggan</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-customer-due-reminder-report") ? (
              <button className="btn btn-primary" onClick={() => setModal("download-report")}>
                <i className="fa fa-download"></i> Download Report
              </button>
            ) : null}
          </div>
        </div>
        <Box title="Laporan Pengingat Pelanggan" loading={loading}>
          <div className="row">
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Pencarian</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="No.Reg/Kode Voucher"
                  value={formFilter.q}
                  onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Pengingat Keterlambatan (hari)</label>
                <input
                  type="number"
                  className="form-control"
                  value={formFilter.reminder_days_after_due}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, reminder_days_after_due: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Tgl. Pengingat mulai</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.reminder_start_date}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, reminder_start_date: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Tgl. Pengingat sampai</label>
                <input
                  type="date"
                  className="form-control"
                  value={formFilter.reminder_end_date}
                  onChange={(event) =>
                    setFormFilter({ ...formFilter, reminder_end_date: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
            {hasAccess("export-customer-due-reminder-report") && (
              <div className="col-12 col-md-auto ml-auto" style={{ paddingTop: "32px" }}>
                {/* <ButtonDownloadReport filter={filter} onSuccess={onSuccess} onError={onError} /> */}
              </div>
            )}
          </div>

          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
          <AlertBox {...alert} setAlert={setAlert} />

          <div className="mt-2" style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th rowSpan={2}>No. Registrasi</th>
                  <th rowSpan={2}>Nama</th>
                  <th rowSpan={2}>Tgl. Pengingat</th>
                  <th rowSpan={2}>Pengingat Keterlambatan</th>
                  <th rowSpan={2}>Status Pembayaran</th>

                  <th colSpan={5}>Voucher</th>

                  <th rowSpan={2}>Periode Pembayaran Terakhir</th>
                  <th rowSpan={2}>Tgl. Pembayaran Terakhir</th>
                </tr>
                <tr>
                  <th>Kode</th>
                  <th>Extra Hari</th>
                  <th>Tgl. Kadaluarsa</th>
                  <th>Tgl. Digunakan</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {reports.length === 0 && (
                  <tr>
                    <td colSpan={12} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {reports.map((report, index) => (
                  <tr key={index}>
                    {/* <td></td> */}
                    <td>{report.registration_number}</td>
                    <td>{report.name}</td>
                    <td className="text-nowrap">
                      {moment(report.reminder_date, "YYYY-MM-DD").format("DD MMM YYYY")}
                    </td>
                    <td>{report.reminder_days_after_due} hari</td>
                    <td>{report.payment ? "PAID" : "UNPAID"}</td>
                    <td>{report.coupon?.code || "-"}</td>
                    <td>{report.coupon?.value || "-"}</td>
                    <td className="text-nowrap">
                      {report.coupon?.expiration_date
                        ? moment(report.coupon.expiration_date, "YYYY-MM-DD").format("DD MMM YYYY")
                        : "-"}
                    </td>
                    <td className="text-nowrap">
                      {report.coupon?.redeem_date
                        ? moment(report.coupon.redeem_date, "YYYY-MM-DD").format("DD MMM YYYY")
                        : "-"}
                    </td>
                    <td className="text-nowrap">
                      {report.coupon ? (
                        <>
                          {report.coupon?.status ? (
                            <span className="badge badge-success">Aktif</span>
                          ) : (
                            <span className="badge badge-danger">Tidak Aktif</span>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-nowrap">{report.last_payment_period || "-"}</td>
                    <td className="text-nowrap">
                      {report.last_payment_date
                        ? moment(report.last_payment_date, "YYYY-MM-DD").format("DD MMM YYYY")
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            {meta.count > meta.page_size && (
              <Pagination
                totalpage={Math.ceil(meta.count / meta.page_size)}
                selectedpage={meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(meta.page + 1)}
                prev={() => gotoPage(meta.page - 1)}
              />
            )}
          </div>
        </Box>
      </div>

      <ModalDownloadReport
        show={modal === "download-report"}
        onSuccess={onSuccess}
        onClose={() => setModal(null)}
      />
    </MainLayout>
  );
}

function ModalDownloadReport({ show, onClose, onSuccess }) {
  const [formFilter, setFormFilter] = useState({
    reminder_start_date: "",
    reminder_end_date: "",
    reminder_days_after_due: "",
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    resetFilter();
    onClose();
  };

  const resetFilter = () => {
    setFormFilter({
      date_from: "",
      date_until: "",
      project_id: "",
      status: "",
      type: "",
      assign_to: "",
    });
  };

  const downloadFile = async () => {
    setLoading(true);
    try {
      let response = await downloadReportCustomerDueReminder({ ...formFilter });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Report Pengingat Pelanggan Jatuh Tempo.xlsx");
      onSuccess("Berhasil mengunduh Report Pengingat Pelanggan Jatuh Tempo.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Download Report Pengingat Pelanggan Jatuh Tempo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form>
          <div className="form-group">
            <label>Pengingat Keterlambatan (hari)</label>
            <input
              type="number"
              className="form-control"
              value={formFilter.reminder_days_after_due}
              onChange={(event) =>
                setFormFilter({ ...formFilter, reminder_days_after_due: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Tgl. Pengingat mulai</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.reminder_start_date}
              onChange={(event) =>
                setFormFilter({ ...formFilter, reminder_start_date: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Tgl. Pengingat sampai</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.reminder_end_date}
              onChange={(event) =>
                setFormFilter({ ...formFilter, reminder_end_date: event.target.value })
              }
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => downloadFile()} disabled={loading}>
          <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
