import { useEffect, useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import ModalHistoryModem from "../../components/inventory/modem/ModalHistoryModem";
import MainLayout from "../../components/layouts/MainLayout";
import Pagination from "../../components/Pagination";
import { CONDITION_INVENTORY, CONDITION_INVENTORY_MODEM_COLOR } from "../../tools/inventorytools";
import { useModemListHooks } from "../../utils/inventory/ModemUtils";
import ModalDownloadDataModem from "../../components/inventory/modem/ModalDownloadDataModem";

export default function DataModemPage() {
  let [filter, setFilter] = useState({
    n: "",
    available: "",
    page: 1,
    page_size: 10,
    history: true,
    status: "",
  });
  let [formFilter, setFormFilter] = useState({
    n: "",
    available: "",
    warehouse_status: "",
    status: "",
  });

  let { modems, meta, loading, errorMsg } = useModemListHooks({ filter });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [selectedId, setSelectedId] = useState("");
  let [showModalDownload, setShowModalDownload] = useState(false);
  let [showModalHistory, setShowModalHistory] = useState(false);

  const toggleOpenModalHistory = (id = null) => {
    setSelectedId(id);
    setShowModalHistory(!showModalHistory);
  };

  useEffect(() => {
    if (errorMsg) {
      setAlert({ show: true, message: errorMsg, type: "danger" });
    }
  }, [errorMsg]);

  const applyFilter = () => {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  };

  const resetFilter = () => {
    setFormFilter({ n: "", available: "", warehouse_status: "", status: "" });
    setFilter({
      n: "",
      available: "",
      warehouse_status: "",
      status: "",
      page: 1,
      page_size: 10,
      history: true,
    });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
  };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Data Inventory Modem</h1>
          </div>
          <div className="col-auto">
            <button className="btn btn-primary" onClick={() => setShowModalDownload(true)}>
              <i className="fas fa-download"></i> Unduh Data Modem
            </button>
          </div>
        </div>
        <Box title="Daftar Modem" loading={loading}>
          <div className="row mb-4">
            <div className="col-12 col-sm-2">
              <label>SN Modem/GPON/No. Reg</label>
              <input
                type="text"
                className="form-control"
                value={formFilter.n}
                onChange={(event) => setFormFilter({ ...formFilter, n: event.target.value })}
              />
            </div>
            <div className="col-12 col-sm-2">
              <label>Ketersediaan</label>
              <select
                name="availability"
                value={formFilter.available}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, available: event.target.value })
                }
                className="form-control"
                id="registration_type"
              >
                <option value="">SEMUA</option>
                <option value="true">Tersedia</option>
                <option value="false">Terpasang/Tidak Tersedia</option>
              </select>
            </div>
            <div className="col-12 col-sm-2">
              <label>Kondisi</label>
              <select
                name="availability"
                value={formFilter.status}
                onChange={(event) => setFormFilter({ ...formFilter, status: event.target.value })}
                className="form-control"
              >
                <option value="">SEMUA</option>
                {CONDITION_INVENTORY.map((condition, index) => (
                  <option key={index} value={index}>
                    {condition}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-sm-2">
              <label>Status Gudang</label>
              <select
                name="warehouse_status"
                value={formFilter.warehouse_status}
                onChange={(event) =>
                  setFormFilter({ ...formFilter, warehouse_status: event.target.value })
                }
                className="form-control"
                id="registration_type"
              >
                <option value="">SEMUA</option>
                <option value="1">Stok Tersedia</option>
                <option value="0">Stok Tidak Tersedia</option>
              </select>
            </div>
            <div className="col-12 col-sm">
              <button
                className="btn btn-primary"
                style={{ marginTop: "32px" }}
                onClick={() => applyFilter()}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                style={{ marginTop: "32px" }}
                onClick={() => resetFilter()}
              >
                Reset
              </button>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />

          <div className="auto-horizontal-scroll mb-3">
            <table className="table table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>SKU</th>
                  <th>Brand</th>
                  <th>Type</th>
                  <th>S/N Modem</th>
                  <th>S/N GPON</th>
                  <th>Ketersediaan</th>
                  <th>No. Registrasi</th>
                  <th>Nama</th>
                  <th>Kondisi</th>
                  <th>Lokasi Modem</th>
                  <th>Gudang</th>
                  <th>Status Gudang</th>
                </tr>
              </thead>
              <tbody>
                {modems.length === 0 && (
                  <tr>
                    <td colSpan={11} className="text-center">
                      Tidak ada data yang ditampilkan
                    </td>
                  </tr>
                )}
                {modems.map((modem, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        className="btn btn-block text-nowrap btn-sm btn-info"
                        onClick={() => toggleOpenModalHistory(modem.id)}
                      >
                        <i className="fa fa-clock"></i> Riwayat
                      </button>
                    </td>
                    <td>{modem.inventory?.sku}</td>
                    <td>{modem.brand}</td>
                    <td>{modem.type}</td>
                    <td>{modem.serial_number_modem}</td>
                    <td>{modem.serial_number_gpon}</td>
                    <td>
                      {modem.available ? (
                        <span className="badge badge-success">Tersedia</span>
                      ) : (
                        <span className="badge badge-danger">Terpasang</span>
                      )}
                    </td>
                    <td>{modem.registration?.registration_number || "-"}</td>
                    <td>{modem.registration?.name || "-"}</td>
                    <td>
                      <span
                        className={`badge badge-${
                          CONDITION_INVENTORY_MODEM_COLOR[modem.status] || "secondary"
                        }`}
                      >
                        {CONDITION_INVENTORY[modem.status] || "-"}
                      </span>
                    </td>
                    <td>{modem.registration?.project?.name || "-"}</td>
                    <td>{modem.warehouse?.name || "-"}</td>
                    <td>
                      {modem.warehouse_status ? (
                        <span className="badge badge-success">Stok Tersedia</span>
                      ) : (
                        <span className="badge badge-danger">Stok Tidak Tersedia</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {meta.count > meta.page_size && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.page_size)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalHistoryModem
        show={showModalHistory}
        onClose={toggleOpenModalHistory}
        id={showModalHistory ? selectedId : ""}
      />
      <ModalDownloadDataModem
        show={showModalDownload}
        onClose={() => setShowModalDownload(false)}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
